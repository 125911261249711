import React, { memo } from 'react'
import SidebarNav from '../Navbar/SidebarNav'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(9),
    paddingBottom: theme.spacing(9),
  },
}))
const Layout = (props) => {
  const classes = useStyles()
  const { MainComponent } = props
  return (
    <div className={classes.root}>
      <CssBaseline />
      <SidebarNav />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container className={classes.container}>{MainComponent}</Container>
      </main>
    </div>
  )
}

export default memo(Layout)
