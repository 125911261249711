import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { publicRoutes, privateRoutes } from './routes'
import LocalStorageUtils from './utils/LocalStorageUtils'
import PrivateRoute from './utils/PrivateRoute'
import React from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function App() {
  const isAuthenticate = () => LocalStorageUtils.localStorageDecoder('token')
  return (
    <BrowserRouter>
      <div className="App">
        <div className="auth_wrapper">
          <div className="auth_inner">
            <Switch>
              {publicRoutes.map((routeProps) => {
                const Component = routeProps.component
                return (
                  <Route
                    key={routeProps.path}
                    exact
                    path={routeProps.path}
                    render={(props) => (isAuthenticate() ? <Redirect to="/" /> : <Component {...props} />)}
                  />
                )
              })}
              {privateRoutes.map((routeProps) => {
                return (
                  <PrivateRoute
                    key={routeProps.path}
                    component={routeProps.component}
                    path={routeProps.path}
                    exact={routeProps.exact}
                    name={routeProps.name}
                  />
                )
              })}
              <Route render={() => <div>Page Not Found.</div>} />
            </Switch>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </BrowserRouter>
  )
}

export default App
