import React from 'react'
import { matchPath, withRouter, useLocation, Link } from 'react-router-dom'
import { privateRoutes } from '../../routes/index'
import SidebarNavItem from './SidebarNavItem'
import { makeStyles } from '@material-ui/core/styles'
import { AppBar, Divider, Drawer, IconButton, List, Toolbar } from '@material-ui/core'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { ExitToApp } from '@material-ui/icons'
import { LocalStorageUtils } from '../../utils'
import WidgetsRoundedIcon from '@mui/icons-material/WidgetsRounded'

const drawerWidth = 230

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 20, // keep right padding when drawer closed
    backgroundColor: '#584999',
  },
  toolbarIcon: {
    backgroundColor: '#584999',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 20px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 26,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    textAlign: 'center',
    textTransform: 'capitalize',
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(8),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  paper: {
    padding: theme.spacing(8),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}))

function SidebarNav(props) {
  const classes = useStyles()
  const [isDrawerOpen, setDrawerOpen] = React.useState(false)
  const handleDrawerOpen = () => {
    setDrawerOpen(true)
  }
  const handleDrawerClose = () => {
    setDrawerOpen(false)
  }

  const currentRoutePath = useLocation().pathname

  const handleLogOut = () => {
    LocalStorageUtils.particularValueRemover('token')
    props.history.push('/login')
  }

  return (
    <>
      <AppBar position="absolute" className={clsx(classes.appBar, isDrawerOpen && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, isDrawerOpen && classes.menuButtonHidden)}
          >
            <img style={{ height: '', width: '' }} src="https://doppelmate.com/images/logo.svg" />
            {/* <WidgetsRoundedIcon style={{ color: '' }} /> */}
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            {/* { privateRoutes.find( route => route.path === currentRoutePath ).title } */}
            {privateRoutes.find((route) => matchPath(currentRoutePath, route)).title}
          </Typography>
          <IconButton color="inherit" onClick={handleLogOut}>
            <ExitToApp />
            {/* <Avatar alt="Doppelmate, Connecting birthday twins" src="https://doppelmate.com/images/logo.svg" /> */}
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !isDrawerOpen && classes.drawerPaperClose),
        }}
        open={isDrawerOpen}
      >
        {/* #EA5580 #1ebdd1 #30C0D6 */}
        <div className={classes.toolbarIcon} style={{ textDecoration: 'none', backgroundColor: '#584999' }}>
          <Link to="/home" style={{ textDecoration: 'none' }}>
            <Typography variant="h6" style={{ color: '#fff', padding: '5px' }} noWrap>
              DoppelMate
            </Typography>
          </Link>
          <IconButton
            onClick={handleDrawerClose}
            style={{ color: '#fff', fontWeight: 'bold', padding: '5px', fontSize: '20px' }}
          >
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List style={{ paddingTop: '30px', backgroundColor: '#FFF', height: '94vh' }}>
          {privateRoutes.map((route) => (
            <SidebarNavItem isDrawerOpen={isDrawerOpen} key={route.path} {...route} />
          ))}
        </List>
      </Drawer>
    </>
  )
}

export default withRouter(SidebarNav)
