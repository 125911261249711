import axios from 'axios'
import LocalStorageUtils from './LocalStorageUtils'

axios.defaults.baseURL = process.env.REACT_APP_ADMIN_API_URL
axios.defaults.headers.common.Authorization = 'Bearer ' + LocalStorageUtils.localStorageDecoder('token')

axios.interceptors.request.use(
  function (config) {
    // config.headers.Authorization = "Bearer " + LocalStorageUtils.localStorageDecoder("token");
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => {
    // intercept the global error
    return response
  },
  async function (error) {
    if (!error.response) {
      return Promise.reject(error)
    }
    const originalRequest = error.config

    if (error.response.status === 401 && originalRequest.url === '/refreshToken') {
      LocalStorageUtils.particularValueRemover('token')
      return Promise.reject(error)
    }

    // if the error is 401 and hasn't already been retried
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      const refreshToken = LocalStorageUtils.localStorageDecoder('refresh_token')
      LocalStorageUtils.particularValueRemover('token')

      if (!(typeof refreshToken === 'string' && refreshToken.length)) {
        window.location = '/login'
        return Promise.reject(error)
      }
      return new Promise((resolve, reject) => {
        axios
          .get('/refreshToken', {
            headers: {
              refreshToken: refreshToken,
            },
          })
          .then(async (tokenRefreshResponse) => {
            LocalStorageUtils.localStorageEncoder('token', tokenRefreshResponse.data.token)
            axios.defaults.headers.common.Authorization = 'Bearer ' + LocalStorageUtils.localStorageDecoder('token')
            resolve(axios(originalRequest))
          })
          .catch(async (error) => {
            window.location = '/login'
            reject(error)
          })
      })
    } else {
      return Promise.reject(error)
    }
  }
)

export default axios
