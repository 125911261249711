import React from 'react'
import loadable from '@loadable/component'

import { BarChart, Description, People, ViewList, SentimentVeryDissatisfied } from '@material-ui/icons'

import ChromeReaderModeRoundedIcon from '@mui/icons-material/ChromeReaderModeRounded'
import AirplayOutlinedIcon from '@mui/icons-material/AirplayOutlined'

import ReportIcon from '@material-ui/icons/Report'

const getLoader = () => <div>Loading....</div>

export const publicRoutes = [
  {
    path: '/login',
    component: loadable(() => import('../pages/Login/Login'), {
      fallback: getLoader(),
    }),
    name: 'login',
  },
]

export const privateRoutes = [
  {
    path: '/home',
    component: loadable(() => import('../pages/Home/Home'), {
      fallback: getLoader(),
    }),
    icon: <AirplayOutlinedIcon style={{ color: '#38414A', fontSize: '22px' }} />,
    name: 'dashboard',
    title: 'Dashboard',
    style: {
      fontSize: '15px',
    },
    navbar: true,
  },
  {
    //  /* #EA5580 #1ebdd1 #30C0D6 */
    path: '/users',
    component: loadable(() => import('../pages/User/Users'), {
      fallback: getLoader(),
    }),
    icon: <People style={{ color: '#38414A', fontSize: '22px' }} />,
    name: 'user',
    title: 'Users',
    navbar: true,
  },
  {
    path: '/user/:userId',
    component: loadable(() => import('../pages/User/UserDetail'), {
      fallback: getLoader(),
    }),
    icon: <ReportIcon style={{ color: '#38414A', fontSize: '22px', font: 'Cerebri Sans' }} />,
    name: 'userDetail',
    title: 'User Profile Details',
    navbar: false,
  },
  {
    //  /* #EA5580 #1ebdd1 #30C0D6 */
    path: '/reported-profiles',
    component: loadable(() => import('../pages/User/ReportedProfiles'), {
      fallback: getLoader(),
    }),
    icon: <SentimentVeryDissatisfied style={{ color: '#38414A', fontSize: '22px' }} />,
    name: 'reportedProfiles',
    title: 'Reported Profiles',
    navbar: true,
  },
  {
    path: '/user-verification-docs',
    component: loadable(() => import('../pages/User/VerificationDocs'), {
      fallback: getLoader(),
    }),
    icon: <Description style={{ color: '#38414A', fontSize: '22px', font: 'Cerebri Sans' }} />,
    name: 'verificationDoc',
    title: 'Profile Verification Requests',
    navbar: true,
  },
  {
    path: '/contact-messages',
    component: loadable(() => import('../pages/ContactUs/ContactMessages'), {
      fallback: getLoader(),
    }),
    icon: <ChromeReaderModeRoundedIcon style={{ color: '#38414A', fontSize: '22px', font: 'Cerebri Sans' }} />,
    name: 'contactMessage',
    title: 'Contact Messages',
    navbar: true,
  },
  {
    path: '/posts',
    component: loadable(() => import('../pages/Post/ReportedPosts'), {
      fallback: getLoader(),
    }),
    icon: <ReportIcon style={{ color: '#38414A', fontSize: '22px', font: 'Cerebri Sans' }} />,
    name: 'posts',
    title: 'Reported Posts',
    navbar: true,
  },
  {
    path: '/post/:postId',
    component: loadable(() => import('../pages/Post/PostDetail'), {
      fallback: getLoader(),
    }),
    icon: <ReportIcon style={{ color: '#38414A', fontSize: '22px', font: 'Cerebri Sans' }} />,
    name: 'postDetail',
    title: 'Reported Post Details',
    navbar: false,
  },
  {
    path: '/templates',
    component: loadable(() => import('../pages/Template/Templates'), {
      fallback: getLoader(),
    }),
    icon: <ViewList style={{ color: '#38414A', fontSize: '22px', font: 'Cerebri Sans' }} />,
    name: 'templates',
    title: 'Notification Template',
    navbar: true,
  },
  {
    path: '/',
    component: loadable(() => import('../pages/Home/Home'), {
      fallback: getLoader(),
    }),
    icon: <BarChart style={{ color: '#38414A', fontSize: '22px', marginTop: '20px', font: 'Cerebri Sans' }} />,
    name: 'home',
    title: 'Dashboard',
  },
]
