import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  icon: {
    minWidth: '27px',
  },
}))
function SidebarNavItem(route) {
  const classes = useStyles()
  return route.navbar ? (
    <ListItem button onClick={() => route.history.push(route.path)}>
      <ListItemIcon className={classes.icon}>{route.icon}</ListItemIcon>
      {route.isDrawerOpen && <ListItemText primary={route.title} />}
    </ListItem>
  ) : (
    ''
  )
}

export default withRouter(SidebarNavItem)
